@import '../helpers/variable';
@import '../helpers/mixins';

.__portfolio__landing {
  display: flex;
  @include smaller-than-sm {
    display: none;
  }
  height: 240px;
  margin: 36px 0px;
  padding: 12px 0px;
  background-color: $color-black-50;
  color: $color-white-100;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  @include font-size(h2);
  @include font-weight(normal);
  @include line-height(h2);

  image {
    width: inherit;
    -webkit-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
    -moz-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
    box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
  }
}

.__portfolio__category {
  @include smaller-than-sm {
    margin-top: 108px;
  }
  div {
    justify-content: center;

    .__portfolio__category-tag {
      margin: 6px 6px;
      padding: 6px 18px;
      border-radius: 5px;
      -webkit-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
      -moz-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
      box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);

      @include smaller-than-sm {
        padding: 6px 8px;
        @include font-size(h4);
        @include line-height(h4);
      }

      &.active {
        background-color: $color-red-100;
        color: $color-yellow-50;
      }
    }
  }

  .el {
    height: 16px;
    width: 16px;
    background-color: green;
  }
}

.__portfolio__container {
  padding: 0px 64px !important;
  @include smaller-than-sm {
    padding: 0px 15px !important;
  }
  background-color: $color-white-100;

  .__portfolio__container-card {
    width: auto;
    opacity: 0;
    &.active {
      -webkit-animation: fadein 0.5s linear forwards;
      animation: fadein 0.5s linear forwards;
    }
    &.hidden {
      display: none;
    }
    @-webkit-keyframes fadein {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .MuiCardHeader-title {
      @include font-size(h5);
      @include font-weight(normal);
      @include line-height(h5);

      @include smaller-than-md {
        @include font-size(h4);
        @include font-weight(normal);
        @include line-height(h4);
      }
    }

    .MuiCardHeader-subheader {
      @include font-size(body1);
      @include font-weight(normal);
      @include line-height(body1);

      @include smaller-than-md {
        @include font-size(h5);
        @include font-weight(normal);
        @include line-height(h5);
      }
    }

    .MuiCardContent-root {
      padding: 8px 16px;

      .MuiTypography-body2 {
        @include font-size(body1);
        @include font-weight(normal);
        @include line-height(body1);

        @include smaller-than-md {
          @include font-size(h5);
          @include font-weight(normal);
          @include line-height(h5);
        }
      }
    }

    .MuiTypography-paragraph {
      @include font-size(body1);
      @include font-weight(normal);
      @include line-height(body1);

      @include smaller-than-md {
        @include font-size(h5);
        @include font-weight(normal);
        @include line-height(h5);
      }
    }
  }
}

.__portfolio__info__title {
  margin: auto auto;
  margin-top: 180px;
  padding: 0 20% !important;
  text-align: right;

  // code for drawing bottom line with gradient effect
  background-image: linear-gradient($color-yellow-10, $color-red-10);
  // Above css will create background image that looks like a border.
  // linear-gradient(steelblue, steelblue);
  // This will create background image for the container.

  background-repeat: no-repeat;

  /* First sizing pair (4px 50%) will define the size of the border i.e border
     will be of having 4px width and 50% height. */
  /* 2nd pair will define the size of stretched background image. */
  background-size: 45% 10%, calc(100% - 50%) 100%;

  /* Similar to size, first pair will define the position of the border
     and 2nd one for the container background */
  background-position: right bottom, 4px 0;

  @include font-size(h2);
  @include font-weight(normal);
  @include line-height(h2);
  @include smaller-than-lg {
    @include font-size(h3);
    @include font-weight(normal);
    @include line-height(h3);

    background-image: linear-gradient($color-yellow-10, $color-red-10);
    background-repeat: no-repeat;
    background-size: 45% 5%, calc(100% - 50%) 100%;
    background-position: right bottom, 4px 0;

    // background-image: none;
    // background-repeat: none;
    // background-size: none;
    // background-position: none;
  }

  @include smaller-than-sm {
    padding: 0 5% !important;
  }
}
.__portfolio__info__container {
  height: fit-content;
  margin: 64px 0px 64px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .__portfolio__info__contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include font-size(h5);
    @include font-weight(regular);
    @include line-height(h5);

    @include smaller-than-lg {
      @include font-size(body1);
      @include font-weight(regular);
      @include line-height(body1);
    }

    @include smaller-than-sm {
      @include font-size(body2);
      @include font-weight(regular);
      @include line-height(body2);
    }

    max-width: 1024px;
    @include smaller-than-lg {
      max-width: 720px;
    }
    @include smaller-than-md {
      max-width: 560px;
    }
    @include smaller-than-sm {
      max-width: 280px;
    }

    // size settings
    img {
      margin-bottom: 32px;
      max-width: 480px;
      @include smaller-than-lg {
        max-width: 360px;
      }
      @include smaller-than-md {
        max-width: 320px;
      }
      @include smaller-than-sm {
        max-width: 280px;
      }
    }

    img#cover {
      max-width: 720px !important;
    }

    // border, shadow effect
    img {
      vertical-align: bottom;
      border-radius: 5px;
      border: 0.5px solid $color-black-10;
      border-left: none;
      border-right: none;
      box-shadow: 10px 20px 10px 0 $color-black-10;
      @include smaller-than-md {
        width: 100%;
      }
    }

    &::after {
      box-shadow: 0 0 60px 10px $color-black-100 inset;
    }
  }
}
