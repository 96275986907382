@import '~bootstrap/scss/bootstrap';
@import './components/cusaccordian';
@import './components/index';
@import './helpers/variableOverrideBS';
@import './helpers/base';
@import './pages/home';
@import './pages/about';
@import './pages/eduexp';
@import './pages/portfolio';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

*:focus {
  outline: none;
}

html {
  min-width: 320px;
}

body {
  position: relative;
  font-family: 'Mukta', 'Montserrat', 'Roboto', 'Open Sans', 'Lora', 'Roboto', 'PingFang SC', sans-serif;
}

.cus-container {
  padding-left: 24px;
  padding-right: 24px;
  @include smaller-than-md {
    padding-left: 16px;
    padding-right: 16px;
  }
}
