@import '../helpers/variable';
@import '../helpers/mixins';

.__about {
  padding: 0px 36px 0px 0px;
  @include font-size(body1);
  @include font-weight(regular);
  @include line-height(body1);

  .__about__textarea {
    padding: 48px 15px;
    @include smaller-than-md {
      padding: 24px 8px;
    }
    background-color: $color-white-100;
  }
  .__about__title {
    @include font-size(h4);
    @include font-weight(medium);
    @include line-height(h3);
    text-align: center;
    margin-bottom: 24px;
    @include smaller-than-md {
      text-align: left;
    }
  }

  .__about__profile__photo {
    width: 400px;
    margin: 20px auto;
    text-align: center;

    img {
      width: inherit;
    }

    @include smaller-than-lg {
      width: 280px;
    }
  }

  .__about__contents {
    display: flex;
    margin-bottom: 24px;
    margin-left: 64px;
    margin-right: 64px;
    flex-direction: row;

    @include font-size(h6);
    @include font-weight(regular);
    @include line-height(h5);

    @include smaller-than-md {
      flex-direction: column;
    }
    .__about__left {
      padding-left: 0px;
      @include smaller-than-md {
        margin-bottom: 24px;
        padding-right: 0px;
      }
    }
    .__about__right {
      margin: auto;
      padding-right: 0px;
      @include smaller-than-md {
        padding-left: 0px;
      }
    }
  }
  .__about__footer {
    @include font-size(h5);
    @include font-weight(regular);
    @include line-height(h5);
  }
}
