@import '../helpers/variable';
@import '../helpers/mixins';

.__header {
  height: 80px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: $color-white-100;
  transition: background-color 0.2s ease;

  @include smaller-than-md {
    height: 60px;
  }
  &.active {
    background-color: transparent;
  }
  &:hover {
    background-color: $color-black-10;
    box-shadow: 0px 0px 1px 0px $color-black-30;
  }
  .__navbar__laptop {
    display: flex;
    @include smaller-than-md {
      display: none;
    }

    .__navbar__laptop__logo {
      a {
        text-decoration: none;
        text {
          color: $color-black-100;
          @include font-size('h4');
          @include font-weight('medium');
          @include line-height('h4');

          &:hover {
            color: $color-yellow-100 !important;
          }
        }
      }
    }

    .__navbar__laptop__functions {
      display: flex;
      justify-content: flex-end;
      .__navbar__laptop__functions-about {
        margin-right: 30px;
      }
      .__navbar__laptop__functions-portfolio {
        margin-left: 30px;
      }
      a {
        text-decoration: none;
        text {
          color: $color-black-50;
          @include font-size('h5');
          @include font-weight('regular');
          @include line-height('h5');

          &:hover {
            color: $color-yellow-50;
          }

          &:active {
            color: $color-yellow-100;
          }
        }
      }
    }
  }

  .__navbar__mobile {
    width: 100%;
    display: none;
    justify-content: space-between;

    @include smaller-than-md {
      display: flex;
    }

    .__navbar__mobile__logo {
      margin: auto 0px;
      a {
        text-decoration: none;
        text {
          color: $color-black-100;
          @include font-weight('regular');
        }
      }

      &.top {
        a > text {
          color: transparent;
        }
      }
    }

    .__navbar__mobile__menu {
      height: fit-content;
      margin: auto 0px;

      padding: 2px;
      img {
        vertical-align: bottom;
      }
    }
  }

  .sidebar-link {
    height: 100%;
    padding-right: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      text-decoration: none;
      color: $color-black-100;
      @include font-size('h5');
      @include font-weight('regular');
      @include line-height('h5');
    }

    a.active {
      color: $color-red-100;
    }
  }

  .visible-enter {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translate(350%);
    background: $color-white-100;
    border-left: 2px solid $color-purple-100;
    margin-top: -25px;
    height: 110vh;
    width: 30%;
    position: absolute;
  }

  .visible-enter-active {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translate(250%);
    transition: transform 0.3s linear;
    margin-top: -25px;
    height: 110vh;
    width: 30%;
    position: absolute;
  }

  .visible-enter-done {
    z-index: 100;
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: space-around;
    background: $color-white-100;
    border-left: 2px solid $color-purple-100;
    position: absolute;
    right: 0px;
    margin-top: -25px;
    height: 110vh;
  }

  .visible-exit {
    z-index: 100;
    transform: translate(250%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: $color-white-100;
    border-left: 2px solid $color-purple-100;
    position: fixed;
    margin-top: -25px;
    height: 110vh;
    width: 30%;
  }

  .visible-exit-active {
    z-index: 100;
    width: 30%;
    transform: translate(350%);
    transition: transform 0.3s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: $color-white-100;
    border-left: 2px solid $color-purple-100;
    position: fixed;
    margin-top: -25px;
    height: 110vh;
  }
}
