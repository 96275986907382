@import '../helpers/variable';
@import '../helpers/mixins';

.__edu-exp {
  padding: 48px;
  @include smaller-than-md {
    padding: 24px;
  }
  background-color: $color-purple-100;
  @include font-size(body1);
  @include font-weight(regular);
  @include line-height(body1);
  flex-direction: row;

  @include smaller-than-md {
    flex-direction: column;
  }

  .__edu-exp__education {
    padding: 0px 15px;
    @include smaller-than-md {
      padding: 0px 8px;
    }
    background-color: $color-purple-100;
    color: $color-white-100;
  }

  .__edu-exp__experience {
    padding: 0px 15px;
    @include smaller-than-md {
      padding: 0px 8px;
    }
    background-color: $color-purple-100;
  }

  .__edu-exp__title {
    color: $color-white-100;
    @include font-size(h4);
    @include font-weight(medium);
    @include line-height(h4);
    text-align: center;
    margin-bottom: 24px;
    @include smaller-than-md {
      text-align: left;
    }
  }

  .__edu-exp__contents {
    margin-bottom: 24px;
    flex-direction: column;

    .summary-title {
      @include font-size(h6);
      @include font-weight(regular);
      @include line-height(h6);
    }

    .summary-subtitle {
      @include font-size(body2);
      @include font-weight(regular);
      @include line-height(body2);
    }

    .__education-card {
      padding: 6px;
      border-bottom: 1px solid #f0f0f0;
      @include font-size(h6);
      @include font-weight(regular);
      @include line-height(h6);

      .title {
        margin-bottom: 6px;
        font-weight: 600;
      }
      .time {
        color: $color-yellow-50;
        @include font-size(body2);
        @include line-height(body2);
      }
      .contents {
        @include font-size(body2);
        @include line-height(body2);
      }
    }
    .__edu-exp__left {
      padding-left: 0px;
    }
    .__edu-exp__right {
      padding-right: 0px;
    }
  }
  .__edu-exp__footer {
  }
}
