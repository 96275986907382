@import '../helpers/variable';
@import '../helpers/mixins';

.__footer {
  width: 100%;
  padding: 48px 8px;
  @include smaller-than-md {
    padding: 24px 8px;
  }
  background-color: $color-white-100;

  .__footer__title {
    margin-bottom: 24px;
    @include font-size(h3);
    @include font-weight(medium);
    @include line-height(h3);
    text-align: center;
  }

  .__footer__desc {
    margin-bottom: 24px;
    @include font-size(h5);
    @include font-weight(regular);
    @include line-height(h5);
    text-align: center;
    p {
      margin-bottom: 8px;
    }
    .__footer__desc-link {
      color: $color-black-100;
      text-decoration: none;
      &:hover {
        color: $color-yellow-50;
      }

      &:active {
        color: $color-yellow-100;
      }
    }
  }

  .__footer__sociallink {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    a {
      @include smaller-than-md {
        padding: 8px;
      }
    }
  }

  .__footer__copyright {
    text-align: center;
    p {
      @include font-size(body1);
      @include font-weight(regular);
      @include line-height(body1);
    }
  }
}
