@import '../helpers/variable';
@import '../helpers/mixins';

body {
  overflow-x: hidden;
  margin-top: 80px !important;
  // TODO: height for test usage
  min-width: 320px;

  @include smaller-than-lg() {
    margin-top: 60px !important;
  }
}

.__home {
  .__home__section__title {
    text-align: left;
    @include font-size(h3);
    @include font-weight(regular);
    @include line-height(h3);
  }

  .__home__top__img {
    padding: 0px;
    // background-color: $color-white-100;
    text-align: center;
    overflow: hidden;
    position: relative;
    img {
      width: 75%;
      -webkit-filter: saturate(9);
      vertical-align: bottom;
      border-radius: 5px;
      border: 0.5px solid $color-black-10;
      border-left: none;
      border-right: none;
      box-shadow: 10px 20px 10px 0 $color-black-10;
      @include smaller-than-md {
        width: 100%;
      }
    }

    &::after {
      box-shadow: 0 0 30px 7px $color-black-100 inset;
    }
  }

  .__home__main {
    .__home__main__bg-img {
      margin: auto;
    }

    .__home__main__carousel {
      ol {
        li {
          width: 10px;
          height: 10px;
          background-color: $color-white-100;
          border-radius: 50%;
        }
      }
    }
  }

  .__home__skill {
    .__home__skill__container {
      padding: 48px 0px;
      @include smaller-than-md {
        padding: 24px 8px;
      }

      .__home__skill-title {
        margin-bottom: 24px;
      }
      .skill-container {
        justify-content: space-around;

        @include smaller-than-md {
          flex-direction: column;
        }

        .skill-section {
          display: flex;
          flex-direction: column;
          justify-content: top;
          align-items: center;

          @include smaller-than-md {
            margin: 12px 0px;
          }

          img {
            max-width: 36px;
            width: 100%;
            margin-bottom: 12px;
          }

          p {
            &.title {
              margin-bottom: 8px;
              @include font-size(h6);
              @include font-weight(bold);
              @include line-height(h6);
            }
            &.desc {
              text-align: center;
              @include font-size(body2);
              @include font-weight(regular);
              @include line-height(body2);
            }
          }
        }
      }
    }
  }

  .__home__contact {
    padding: 48px 8px;
    @include smaller-than-md {
      padding: 24px 8px;
    }
    background-color: $color-purple-100;
    color: $color-white-100;

    .__home__contact__container {
      width: 100%;
      @include smaller-than-md {
        flex-direction: column;
      }
      .title {
        margin-bottom: 24px;
        @include font-size(h3);
        @include font-weight(medium);
        @include line-height(h3);
      }

      .contents {
        padding-left: 12px;
        @include font-size(h5);
        @include font-weight(regular);
        @include line-height(h5);

        .item {
          p {
            width: 80px;
            display: inline-block;
            &:last-child {
              width: auto;
              padding-left: 36px;
              @include smaller-than-md {
                padding-left: 0px;
              }
            }
          }
        }
      }

      .__home__contact-email-img {
        @include smaller-than-md {
          display: none;
        }
        width: 4rem;
        text-align: center;
        justify-content: center;
        align-self: center;
        img {
          width: inherit;
          color: white;
        }
      }

      .__home__contact-email {
        form {
          padding: 0px 8px;
          text-align: center;
          @include smaller-than-sm {
            padding: 0px;
          }
          .input-field {
            margin-bottom: 16px;
            text-align: right;
          }
          lable {
            @include font-size(body1);
            @include font-weight(regular);
            @include line-height(body1);
          }
          input {
            width: 100%;
            padding: 4px 8px;
            background-color: $color-yellow-100;
            color: $color-white-100;
            @include font-size(body1);
            @include font-weight(regular);
            @include line-height(body1);
            border-radius: 3px;
            border: none;
            border-bottom: 1px solid $color-black-50;
            &:hover,
            &:active {
              background-color: $color-white-100;
              color: $color-black-100;
            }
            &::placeholder {
              color: $color-black-30;
            }
          }

          textarea {
            width: 100%;
            padding: 4px 8px;
            background-color: $color-yellow-100;
            color: $color-white-100;
            @include font-size(body1);
            @include font-weight(regular);
            @include line-height(body1);
            &:hover,
            &:active {
              background-color: $color-white-100;
              color: $color-black-100;
            }

            &::placeholder {
              color: $color-black-30;
            }
          }

          button {
            padding: 6px 14px;
            color: $color-white-100;
            @include font-size(body1);
            @include font-weight(regular);
            @include line-height(body1);
            border: 1px solid $color-white-100;
            -webkit-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
            -moz-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
            box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);

            &:hover,
            &:active {
              color: $color-yellow-100;
              border: 1px solid $color-yellow-100;
            }
          }
        }
      }
    }
  }
}
