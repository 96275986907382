@import '../helpers/variable';
@import '../helpers/mixins';

@keyframes invisiblyGrowFontSize {
  0% {
    font-size: 0;
    opacity: 0;
  }
  100% {
    font-size: 1em;
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
details[open] .summary-content {
  animation-name: invisiblyGrowFontSize, fadeIn;
  animation-duration: 300ms, 200ms;
  animation-delay: 0ms, 300ms;
}

details {
  width: auto !important;
  position: relative;
  margin-bottom: 15px;
  background-color: $color-white-100;
  border: solid 2px $color-black-30;

  summary {
    @include font-size(body1);
    @include line-height(body1);
    font-weight: 600;
    text-align: left;

    .summary-title {
      a {
        color: black;
      }
    }
    .summary-subtitle {
      justify-content: space-between;
      @include font-size(body2);
      @include line-height(body2);
      @include font-weight(regular);

      .time {
        color: $color-yellow-100;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  .summary-content {
    border-top: 2px solid $color-black-30;
    cursor: default;
    margin: 0px 1em;
    padding: 1em 0px;
    @include font-size(body2);
    @include line-height(body2);
    @include font-weight(regular);
  }

  summary {
    padding: 1em;

    &:focus {
      outline: none;
    }
  }

  .summary-icon {
    pointer-events: none;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    background: #ffffff;

    svg {
      display: block;
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }
}
